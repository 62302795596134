import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import { useAlternateLangs } from "../../components/Hreflangs";
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-consultant"
);

const breadCrumbLevels = {
  Hjem: "/",
  "Om mig": "/da/om-mig",
  "Google Tag Manager Konsulent": "/da/google-tag-manager-konsulent"
};


const GoogleTagManagerConsultant = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Freelance Google Tag Manager Konsulent: M. Kupperschmidt"
        description="Matthias Kupperschmidt er freelance konsulent og udvikler for Google Tag Manager. Læs om hans baggrund, services og anmod om et tilbud."
        lang="da"
        canonical="/da/google-tag-manager-konsulent"
        image="tag-management-consultant-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="tag-manager-consultant/matthias-kupperschmidt-presenting-at-founders-house-oct-2019.jpg"
          alt="Google Tag Manager freelance konsulent Matthias Kupperschmidt præsenterer i Founders House, oktober 2019"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Konsulent</H>
        <ProfileCard
          tags={["Tracking Setup", "Retargeting", "Ecommerce Tracking", "Google Analytics", "Custom Event Tracking", "JavaScript", "React", "Python", "EN, DE, DA"]}
          profession="Google Tag Manager Konsulent"
          rate={`${hourlyRate}€/t`}
          location="remote fra København, DK"
          cta="Kontakt"
          alt="GTM ekspert"
        />
        <p>Jeg er en Google Tag Manager konsulent, og jeg planlægger og implementerer <Link to="/da/tag-styring">tag management systemer</Link> for mine kunder.</p>
        <p>
          Med tag management systemer kan du hurtigt og nemt implementere flere tredjeparts tags (f.eks. Google Analytics, Facebook
          pixel, Google Ads) på din hjemmeside.
        </p>
        <p className="baseline">
          Tag management platforme er blevet en meget populær marketing teknologi i de seneste år. Faktisk forventes det globale tag management system marked at fortsætte med <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="">en årlig vækstrate på 5,7% indtil 2024</a>.
        </p>

        <p className="baseline">
          Google Tag Manager (GTM) er den mest udbredte tag management
          løsning med en <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">markedsandel på 94% inden for tag management markedet</a> og <b>gratis</b> i sin standardversion.
        </p>
        <p>
          Gennem GTM implementerer jeg tracking opsætninger integreret med tredjeparts tags, der <b>kan skaleres over flere hjemmesider</b> og
          sprog.
        </p>
        <p>
          Det gør det muligt for dig at bruge brugerinteraktioner fra din Google Analytics konto til at oprette bruger
          segmenter til dine marketingindsatser, som kampagner på Facebook Ads, Doubleclick eller Linkedin.
        </p>
        <br />
        <br />
        <H as="h6" style={{ "textAlign": "center" }}>Hvem jeg har arbejdet med</H>
        <ImgScreenshot
          src="about/clients_700px.png"
          alt="kunder jeg har arbejdet med"
          className="article-img"
        />
        <br />
        <br />
        <br />


        <H as="h2" style={{ "textAlign": "center" }}>Få et tilbud</H>
        <ContactForm showHeadline={false} formName="ga konsulent (DA) - kontakt formular" />

        <H as="h2">Services</H>
        <p>
          Som freelance Google Tag Manager konsulent tilbyder jeg de samme services (eller mere) som en typisk digital
          marketingbureau. Du sparer tid og budget, da kommunikationen er direkte med Google Tag Manager eksperten. Ingen
          projektledelse og administrationsomkostninger.
        </p>
        <p>Her er en kort liste over mine <b>Google Tag Manager konsulent services</b>:</p>
        <ul>
          <li>Google Tag Manager installation</li>
          <li>cookie bannere</li>
          <li><Link to="/da/event-sporing">event tracking</Link></li>
          <li>
            <Link to="/da/google-analytics-opsætning">Google Analytics implementering</Link>
          </li>
          <li>kampagnekoordination med medie- og webbureauer</li>
          <li>forbedret eCommerce tracking</li>
          <li>
            <Link to="/da/data-lag">data layer implementering</Link>
          </li>
          <li>data integration på tværs af tags</li>
          <li>retargeting med f.eks. Facebook, Google Ads, Twitter, Linkedin</li>
          <li>mål konvertering tracking</li>
          <li>affiliate tracking</li>
          <li>kampagne tracking</li>
          <li>video tracking</li>
          <li>Data import og eksport</li>
          <li>fejlfinding og fejlretning</li>
          <li>custom tag løsning med JavaScript</li>
        </ul>
        <p>
          Enhver tagging løsning kan implementeres og ændres hurtigt via <b>GTM containeren</b> eller enhver anden tag
          management løsning. GTM har udviklet sig som en standard for håndtering af data, marketing tags, analytics og custom tag implementeringer, primært fordi en GTM konto er gratis og fungerer pålideligt.
        </p>
        <p>
          Siden dens udgivelse har marketingsfolk endelig kunnet udføre og justere deres digitale kampagnestrategi meget fleksibelt
          da der ikke kræves ændringer i kildekoden.
        </p>
        <H as="h2">Hvad er Google Tag Manager?</H>
        <p>
          Google Tag Manager er et værktøj til at implementere og konfigurere tags på en hjemmeside. Du kan definere regler for udførelsen af hver kode snippet og præcisere, hvilke data den har adgang til.
        </p>
        <p>
          Alt gøres fra den brugervenlige Google Tag Manager grænseflade, som leverer en hurtig og glat udvikleroplevelse. Implementering af nye tags gennem en grafisk brugergrænseflade (GUI) i sig selv er en velkommen ny oplevelse, fordi det kræver mindre koncentration og sparer implementeringstid.
        </p>
        <p>
          <b>Google Tag Manager er essentielt en <Link to="/da/wiki-analytics/container-maerke">container tag</Link> gennem hvilken du kan implementere alle andre marketing tags på din
            hjemmeside</b>. Kun Google Tag Manager kode snippet går ind i kildekoden og de resterende kode snippets kan implementeres
          og konfigureres i skala fra Google Tag Manager kontoen.
        </p>
        <p>Du kan endda teste enhver ny tag med dens forhåndsvisningstilstand for at sikre, at alt fungerer som det skal, før du sender rigtige data til dit analytics værktøj.</p>
        <p className="baseline">
          Gennem dens fleksibilitet er det blevet meget populært for online marketing og datastyring, især med
          Google produkter. Det er så populært, at det er <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">brugt af 30,4% af hjemmesiderne</a> på nettet, <a href="https://trends.builtwith.com/widgets/Google-Tag-Manager" target="_blank">med 4,9 millioner live hjemmesider, der bruger GTM</a>. Google Analytics tags, der deler data med reklameservices (f.eks. Facebook, Google Ads)
          er typiske brugsscenarier.
        </p>
        <p>
          Læs en mere detaljeret{" "}
          forklaring af <Link to="/da/hvad-er-google-tag-manager">hvad Google Tag Manager er</Link> og se nogle
          skærmbilleder af Google Tag Manager koden og dens grænseflade, hvis du vil vide mere.
        </p>
        <p>
          Hvis du vil lære, hvordan du bruger Google Tag Manager med Google Analytics, læs min{" "}
          guide om <Link to="/da/google-analytics-opsætning">hvordan man implementerer en Google Analytics opsætning med GTM</Link> og
          også <Link to="/da/event-sporing">hvordan man opsætter event tracking med GTM</Link>.
        </p>

        <H as="h2">Hvad er fordelene ved Google Tag Manager?</H>
        <p>
          Den største fordel ved Google Tag Manager er den hurtige og fleksible implementering af flere tags og den skalerbare data
          håndtering blandt dem.
        </p>
        <p>
          Disse forholdsvis små implementeringsændringer koster tid og akkumuleres for kunder, der kører mange digitale
          kampagner. Gode programmører er dyre, og bureauer har begrænsede ressourcer. Derfor giver det mening at
          outsource tag implementeringsopgaver til et værktøj med en grafisk brugergrænseflade (GUI) og hurtigere implementering
          tid.
        </p>
        <p>
          Læs mere om en{" "}
          <Link to="/da/fordele-ved-google-tag-manager">dybdegående liste over fordelene ved Google Tag Manager</Link>, for at vide
          mere.
        </p>

        <H as="h2">Hvorfor har du brug for Google Tag Manager rådgivning?</H>
        <p>
          En GTM konsulent planlægger Google Analytics implementeringen og sørger for, at andre marketingservices får
          de rigtige data om f.eks. kampagnebesøg og konverteringer for at optimere dine segmenter.
        </p>
        <p>
          Hvis du kører mange kampagner med individuelle landingssider, vil en GTM specialist hurtigt få tilføjet konverteringstracking
          til dine analytics rapporter.
        </p>
        <p>
          Ved at tracke hændelserne på din landingsside kan du følge kampagnens løbende ydeevne. Hvis kampagnen ikke præsterer som forventet, vil du opdage det tidligt og kan justere. Denne feedback-cyklus for
          hver kampagne bringer lærdomme til at optimere fremtidige kampagner.
        </p>

        <p>
          En Google Tag Manager ekspert fungerer også som udvikler i dit marketingteam og hjælper med tagging,
          JavaScript opgaver og custom løsninger omkring din side.
        </p>
        <p>
          GTM rådgivning bliver stadig vigtigere for kunder, der er afhængige af andre digitale marketingservices for deres
          forretning. Det maksimerer værdien af hver sidevisning med højkvalitets engagement data om SEO, email og
          retargeting kampagner for at hjælpe dig med at optimere dit annonceforbrug.
        </p>

        <H as="h2">Hvor meget koster GTM rådgivning?</H>
        <p className="baseline">
          Min freelance rate er <b>{hourlyRate}€</b> per time net. Hvis jeg skal planlægge en Google Analytics implementering
          for en kunde, estimerer jeg omfanget af arbejdet og sender et forslag, der beskriver alle leverancer. Omfanget er
          afhængig af størrelsen på hjemmesiden og antallet af KPI'er, der skal trackes.
        </p>
        <p className="baseline">
          Hjemmesidens underliggende teknologi spiller også en rolle i forhold til implementeringens lethed. Google Analytics
          opsætninger med event tracking og 3rd-party data integration koster mellem <b>1800€</b> og <b>6000€</b> net.
        </p>
        <p>
          Hvis du har brug for kontinuerlig hjælp med Google Tag Manager for en større side, kan et månedligt retainer budget efter
          forventet arbejdsbyrde aftales. Månedlige retainer skal tage højde for et minimum på 10 timer per måned.
        </p>
        <p className="baseline">
          Mindre opgaver faktureres med et fast gebyr mellem <b>100€</b> og <b>300€</b>.
        </p>
        <H as="h2">Min profil</H>
        <p>
          Jeg byggede min første hjemmeside, da jeg var 14 år gammel, for at bytte film med mine klassekammerater. Da jeg var 18, begyndte jeg
          at drive en e-commerce butik for bordfodbold kaldet "Kickerkult". Da jeg blev mere interesseret i Analytics værktøjer, SEO
          og sendte mine første nyhedsbreve, blev jeg hooked på nettet. Siden da har jeg ønsket at fokusere min karriere på
          internettet.{" "}
        </p>
        <p>
          Jeg har arbejdet i digitale bureauer siden 2011 og fokuserede udelukkende på SEO og Analytics siden 2015. Jeg byggede tag
          management systemer med GTM for e-commerce sider, supermarkedskæder, bilproducenter, restaurantkæder,
          forsikringsselskaber og mange andre sektorer. Mine kunder har tendens til at være virksomheder, store firmaer eller de
          afspejler på en eller anden måde en lignende organisation og procedurer internt.
        </p>
        <p>
          Jeg har arbejdet som selvstændig Google Tag Manager freelance konsulent siden 2018. Jeg laver også søgemaskine
          optimering og <Link to="/da/analyse-konsulent">analytics rådgivning</Link>. Alt ud af én hånd.
        </p>
        <p>
          Min personlighed er analytisk, rationel og pragmatisk. Jeg kan lide analyser for at finde den rigtige løsning, men også
          ikke gøre tingene komplicerede. Derfor var det en logisk konsekvens for mig at træde ud af det hektiske bureau for at arbejde for mig selv som en
          GTM freelancer.
        </p>
        <p>
          For at holde mit know-how opdateret med tag management, analytics og SEO, driver jeg nogle få hjemmesider og besøger
          konferencer. Jeg medorganiserer også Copenhagen SEO-Meetup.
        </p>

        <p>
          Du kan læse mere om min baggrund på <a href="https://www.linkedin.com/in/matthiaskupperschmidt">Linkedin</a> eller finde mere information på min{" "}
          <Link to="/da/om-mig">om side</Link>.
        </p>
        <H as="h2">Leder du efter en freelance Google Tag Manager specialist?</H>
        <p>
          Kontakt mig, hvis du leder efter en til at hjælpe dig med en Google Tag Manager implementering. Jeg kan rådgive
          dig om, hvilke events der skal trackes og hvilke indikatorer der skal kigges på for at optimere dine konverteringer.
        </p>
        <p>
          Anmod om et tilbud gennem <Link to="/da/kontakt">kontakt siden</Link> og jeg vil vende tilbage med et forslag.
        </p>
        {/* <RelatedContent /> */}
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GoogleTagManagerConsultant;